import React from "react"

import { Container } from 'reactstrap'
import styled from 'styled-components'
import {SiteConfig} from '../data/siteConfig'
import NumberFormat from 'react-number-format'
import "../assets/stylesheets/layout.scss"

let UnderConstruction = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  h1, h2, h4 {
    text-align: center;
  }
`

let Index = () => {
  let {title, phone} = SiteConfig()
  return (
    <UnderConstruction>
      <Container>
        <h1>{title}</h1>
        <h2>Under Construction - Coming Soon</h2>
        <h4>
          <NumberFormat value={phone} displayType={'text'} format="#### ### ###"/>
        </h4>
      </Container>
    </UnderConstruction>
  )
}

export default Index
